<div class="max-container">
    @if (translateService.de) {
    <div class="police-container">
        <h1>Datenschutzerklärung</h1>
        <p>Stand: 14. März 2024</p>
        <h2 id="m3">Verantwortlicher</h2>
        <p>Swen, Breitung <br>Heutalsweg, 28<br>99867, Gotha, Deutschland</p>
        E-Mail-Adresse: <a href="mailto:veroxa@web.de">Swen Breitung</a>

        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.</p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
            <li>Kontaktdaten.</li>
            <li>Inhaltsdaten.</li>
            <li>Nutzungsdaten.</li>
            <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
            <li>Kommunikationspartner.</li>
            <li>Nutzer.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
            <li>Feedback.</li>
            <li>Marketing.</li>
            <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li>Informationstechnische Infrastruktur.</li>
        </ul>
        <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
        <p><strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen
            der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.</p>
        <ul>
            <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.</li>
            <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</strong> - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher
                Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.</li>
            <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und
                Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.</li>
        </ul>
        <p><strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener
            Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener
            Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.</p>
        <p><strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach dem schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch nach der Datenschutzgrundverordnung
            (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung"
            von „Personendaten", "überwiegendes Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse" und "besondere Kategorien
            von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.</p>

        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten
            und des Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
        <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe,
            der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen
            wir den Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>

        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber offengelegt werden. Zu den
            Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen
            insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>

        <h2 id="m24">Internationale Datentransfers</h2>
        <p>Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland (d. h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
            oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines Angemessenheitsbeschlusses
            anerkannt wurde (Art. 45 DSGVO), dient dieser als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur dann, wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46 Abs. 2
            lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen Anbietern aus
            dem Drittland mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot der EU-Kommission entnommen werden: <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
                target="_blank">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.</a></p>
        <p>EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data Privacy Framework" (DPF) hat die EU-Kommission das Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der Angemessenheitsbeschlusses vom 10.07.2023
            als sicher anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere Informationen zu dem DPF können Sie der Webseite des Handelsministeriums der USA unter <a href="https://www.dataprivacyframework.gov/" target="_blank">https://www.dataprivacyframework.gov/</a>            (in Englisch) entnehmen. Wir informieren Sie im Rahmen der Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter dem Data Privacy Framework zertifiziert sind.</p>

        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:</p>
        <ul>
            <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>
            <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.</li>
            <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen
                Vorgaben.
            </li>
            <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</li>
            <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen
                Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.</li>
            <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
                oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.</li>
            <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.</li>
        </ul>

        <h2 id="m134">Einsatz von Cookies</h2>
        <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern und Informationen aus den Endgeräten auslesen. Z. B. um den Login-Status in einem Nutzerkonto, einen Warenkorbinhalt in einem E-Shop, die
            aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z. B. zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie
            der Erstellung von Analysen der Besucherströme. </p>
        <p><strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung
            ist insbesondere nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot)
            zur Verfügung zu stellen. Zu den unbedingt erforderlichen Cookies gehören in der Regel Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des Onlineangebotes , dem Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und Auswahlmöglichkeiten
            der Nutzer oder ähnlichen mit der Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
            und enthält die Informationen zu der jeweiligen Cookie-Nutzung.</p>
        <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung
            bitten. Falls die Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z. B. an einem
            betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies erforderlich ist, um unsere
            vertraglichen Verpflichtungen zu erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und Verarbeitungsprozessen auf.</p>
        <p><strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies unterschieden:</p>
        <ul>
            <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und sein Endgerät (z. B. Browser oder mobile Applikation) geschlossen
                hat.
            </li>
            <li><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des Endgerätes gespeichert. So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine
                Website erneut besucht. Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet werden. Sofern wir Nutzern keine expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen
                der Einholung der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.</li>
        </ul>
        <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"): </strong>Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und der Verarbeitung entsprechend den gesetzlichen Vorgaben widersprechen. Hierzu können
            Nutzer unter anderem die Verwendung von Cookies in den Einstellungen ihres Browsers einschränken (wobei dadurch auch die Funktionalität unseres Onlineangebotes eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
            kann auch über die Websites <a href="https://optout.aboutads.info/" target="_new">https://optout.aboutads.info</a> und <a href="https://www.youronlinechoices.com/" target="_new">https://www.youronlinechoices.com/</a> erklärt werden.</p>
        <ul class="m-elements">
            <li class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</li>
        </ul>
        <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
        <ul class="m-elements">
            <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: </strong>Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im Rahmen der Einwilligungs-Management-Lösung
                genannten Verfahren und Anbietern eingeholt wird. Dieses Verfahren dient der Einholung, Protokollierung, Verwaltung und dem Widerruf von Einwilligungen, insbesondere bezogen auf den Einsatz von Cookies und vergleichbaren Technologien,
                die zur Speicherung, zum Auslesen und zur Verarbeitung von Informationen auf den Endgeräten der Nutzer eingesetzt werden. Im Rahmen dieses Verfahrens werden die Einwilligungen der Nutzer für die Nutzung von Cookies und die damit verbundenen
                Verarbeitungen von Informationen, einschließlich der im Einwilligungs-Management-Verfahren genannten spezifischen Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen.
                Die Einwilligungserklärungen werden gespeichert, um eine erneute Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß der gesetzlichen Anforderungen führen zu können. Die Speicherung erfolgt serverseitig und/oder in einem Cookie
                (sogenanntes Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die Einwilligung einem spezifischen Nutzer oder dessen Gerät zuordnen zu können. Sofern keine spezifischen Angaben zu den Anbietern von Einwilligungs-Management-Diensten
                vorliegen, gelten folgende allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der Einwilligung, den Angaben
                zum Umfang der Einwilligung (z. B. betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie Informationen über den Browser, das System und das verwendete Endgerät gespeichert wird; <span class=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</span></li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser
            oder das Endgerät der Nutzer zu übermitteln.</p>
        <ul class="m-elements">
            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).</li>
            <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
            <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server
                etc.).). Sicherheitsmaßnahmen.
            </li>
            <li class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
        </ul>
        <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
        <ul class="m-elements">
            <li><strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien,
                Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der anfragende
                Provider gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B., um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und
                zum anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen; <span class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). </span><strong>Löschung von Daten:</strong> Logfile-Informationen
                werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                ausgenommen.
            </li>
        </ul>
        <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
        <p>Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung
            der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
        <ul class="m-elements">
            <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen); Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).</li>
            <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
            <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z. B. Sammeln von Feedback via Online-Formular). Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).</li>
        </ul>
        <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
        <ul class="m-elements">
            <li><strong>Kontaktformular: </strong>Wenn Nutzer über unser Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;
                <span class=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span></li>
        </ul>
        <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
        <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.</p>
        <p>Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z. B. die Durchsetzung der Rechte der Nutzer erschwert werden
            könnte.
        </p>
        <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z. B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt
            werden. Die Nutzungsprofile können wiederum verwendet werden, um z. B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf
            den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere,
            wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>
        <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.</p>
        <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer
            und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.</p>
        <ul class="m-elements">
            <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen); Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations-
                und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).</li>
            <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
            <li><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Feedback (z. B. Sammeln von Feedback via Online-Formular). Marketing.</li>
            <li class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
        </ul>
        <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
        <ul class="m-elements">
            <li><strong>LinkedIn: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland; <span class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>                <a href="https://www.linkedin.com" target="_blank">https://www.linkedin.com</a>; <strong>Datenschutzerklärung:</strong> <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>;
                <strong>Grundlage Drittlandtransfers:</strong> Data Privacy Framework (DPF); <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
                <strong>Weitere Informationen:</strong> Wir sind gemeinsam mit LinkedIn Irland Unlimited Company für die Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der Besucher, die zu Zwecken der Erstellung der „Page-Insights" (Statistiken)
                unserer LinkedIn-Profile verantwortlich. <br>Zu diesen Daten gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen oder mit denen sie interagieren, oder die von ihnen vorgenommenen Handlungen sowie Informationen über
                die von den Nutzern genutzten Geräte (z. B. IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen, Cookie-Daten) und Angaben aus dem Profil der Nutzer, wie Berufsfunktion, Land, Branche, Hierarchieebene, Unternehmensgröße und Beschäftigungsstatus.
                Datenschutzinformationen zur Verarbeitung von Daten der Nutzer durch LinkedIn können den Datenschutzhinweisen von LinkedIn entnommen werden: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>                <br>Wir haben mit LinkedIn Irland eine spezielle Vereinbarung abgeschlossen ("Page Insights Joint Controller Addendum (the ‚Addendum‘)", <a href="https://legal.linkedin.com/pages-joint-controller-addendum" target="_blank">https://legal.linkedin.com/pages-joint-controller-addendum</a>),
                in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen LinkedIn beachten muss und in der LinkedIn sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder Löschungsanfragen direkt an LinkedIn
                richten). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit LinkedIn nicht eingeschränkt. Die gemeinsame Verantwortlichkeit beschränkt
                sich auf die Erhebung der Daten durch und die Übermittlung an die Ireland Unlimited Company, ein Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der Daten obliegt ausschließlich der Ireland Unlimited Company, was insbesondere
                die Übermittlung der Daten an die Muttergesellschaft LinkedIn Corporation in den USA betrifft.</li>
            <li><strong>Xing: </strong>Soziales Netzwerk; <strong>Dienstanbieter:</strong> New Work SE, Am Strandkai 1, 20457 Hamburg, Deutschland; <span class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>                <a href="https://www.xing.com/" target="_blank">https://www.xing.com/</a>. <strong>Datenschutzerklärung:</strong> <a href="https://privacy.xing.com/de/datenschutzerklaerung" target="_blank">https://privacy.xing.com/de/datenschutzerklaerung</a>.</li>
        </ul>
        <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
        <p>Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet als "Drittanbieter") bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder Stadtpläne
            handeln (nachfolgend einheitlich bezeichnet als "Inhalte").</p>
        <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte
            oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken,
            auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können
            ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
            enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>
        <ul class="m-elements">
            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).</li>
            <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
            <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
        </ul>
        <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
        <ul class="m-elements">
            <li><strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von Schriften (und Symbolen) zum Zwecke einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriften und Symbolen im Hinblick auf Aktualität und Ladezeiten, deren
                einheitliche Darstellung und Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem Anbieter der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt, damit die Schriftarten im Browser des Nutzers zur Verfügung gestellt werden
                können. Darüber hinaus werden technische Daten (Spracheinstellungen, Bildschirmauflösung, Betriebssystem, verwendete Hardware) übermittelt, die für die Bereitstellung der Schriften in Abhängigkeit von den verwendeten Geräten und der technischen
                Umgebung notwendig sind. Diese Daten können auf einem Server des Anbieters der Schriftarten in den USA verarbeitet werden - Beim Besuch unseres Onlineangebotes senden die Browser der Nutzer ihre Browser HTTP-Anfragen an die Google Fonts
                Web API (d. h. eine Softwareschnittstelle für den Abruf der Schriftarten). Die Google Fonts Web API stellt den Nutzern die Cascading Style Sheets (CSS) von Google Fonts und danach die in der CCS angegebenen Schriftarten zur Verfügung.
                Zu diesen HTTP-Anfragen gehören (1) die vom jeweiligen Nutzer für den Zugriff auf das Internet verwendete IP-Adresse, (2) die angeforderte URL auf dem Google-Server und (3) die HTTP-Header, einschließlich des User-Agents, der die Browser-
                und Betriebssystemversionen der Websitebesucher beschreibt, sowie die Verweis-URL (d. h. die Webseite, auf der die Google-Schriftart angezeigt werden soll). IP-Adressen werden weder auf Google-Servern protokolliert noch gespeichert und
                sie werden nicht analysiert. Die Google Fonts Web API protokolliert Details der HTTP-Anfragen (angeforderte URL, User-Agent und Verweis-URL). Der Zugriff auf diese Daten ist eingeschränkt und streng kontrolliert. Die angeforderte URL identifiziert
                die Schriftfamilien, für die der Nutzer Schriftarten laden möchte. Diese Daten werden protokolliert, damit Google bestimmen kann, wie oft eine bestimmte Schriftfamilie angefordert wird. Bei der Google Fonts Web API muss der User-Agent
                die Schriftart anpassen, die für den jeweiligen Browsertyp generiert wird. Der User-Agent wird in erster Linie zum Debugging protokolliert und verwendet, um aggregierte Nutzungsstatistiken zu generieren, mit denen die Beliebtheit von Schriftfamilien
                gemessen wird. Diese zusammengefassten Nutzungsstatistiken werden auf der Seite „Analysen" von Google Fonts veröffentlicht. Schließlich wird die Verweis-URL protokolliert, sodass die Daten für die Wartung der Produktion verwendet und ein
                aggregierter Bericht zu den Top-Integrationen basierend auf der Anzahl der Schriftartenanfragen generiert werden kann. Google verwendet laut eigener Auskunft keine der von Google Fonts erfassten Informationen, um Profile von Endnutzern
                zu erstellen oder zielgerichtete Anzeigen zu schalten; <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland; <span class=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>                <a href="https://fonts.google.com/" target="_blank">https://fonts.google.com/</a>; <strong>Datenschutzerklärung:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>; <strong>Grundlage Drittlandtransfers:</strong>                Data Privacy Framework (DPF). <strong>Weitere Informationen:</strong> <a href="https://developers.google.com/fonts/faq/privacy?hl=de" target="_blank">https://developers.google.com/fonts/faq/privacy?hl=de</a>.</li>
        </ul>
        <p class="seal"><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
    </div>} @if (translateService.en) {
    <div class="police-container">
        <h1>Privacy Policy</h1>
        <p>Last Updated: March 14, 2024</p>
        <h2>Table of Contents</h2>
        <ul class="index">
            <li><a class="index-link" href="#m3">Controller</a></li>
            <li><a class="index-link" href="#mOverview">Overview of Processing Activities</a></li>
            <li><a class="index-link" href="#m2427">Relevant Legal Bases</a></li>
            <li><a class="index-link" href="#m27">Security Measures</a></li>
            <li><a class="index-link" href="#m25">Transfer of Personal Data</a></li>
            <li><a class="index-link" href="#m24">International Data Transfers</a></li>
            <li><a class="index-link" href="#m10">Rights of Data Subjects</a></li>
            <li><a class="index-link" href="#m134">Use of Cookies</a></li>
            <li><a class="index-link" href="#m225">Provision of the Online Offer and Web Hosting</a></li>
            <li><a class="index-link" href="#m182">Contact and Request Management</a></li>
            <li><a class="index-link" href="#m136">Social Media Presence</a></li>
            <li><a class="index-link" href="#m328">Plugins and Embedded Functions and Content</a></li>
        </ul>
        <h2 id="m3">Controller</h2>
        <p>Swen Breitung <br>Heutalsweg 28<br>99867 Gotha, Germany</p>
        Email Address: <a href="mailto:veroxa@web.de">Swen Breitung</a>

        <h2 id="mOverview">Overview of Processing Activities</h2>
        <p>The following overview summarizes the types of data processed and the purposes of their processing and refers to the data subjects.</p>
        <h3>Types of Processed Data</h3>
        <ul>
            <li>Contact data.</li>
            <li>Content data.</li>
            <li>Usage data.</li>
            <li>Meta, communication, and procedural data.</li>
        </ul>
        <h3>Categories of Data Subjects</h3>
        <ul>
            <li>Communication partners.</li>
            <li>Users.</li>
        </ul>
        <h3>Purposes of Processing</h3>
        <ul>
            <li>Contact requests and communication.</li>
            <li>Security measures.</li>
            <li>Administration and response to inquiries.</li>
            <li>Feedback.</li>
            <li>Marketing.</li>
            <li>Provision of our online offer and user-friendliness.</li>
            <li>Information technology infrastructure.</li>
        </ul>
        <h2 id="m2427">Relevant Legal Bases</h2>
        <p><strong>Relevant legal bases under the GDPR: </strong>The following provides an overview of the legal bases of the GDPR on which we process personal data. Please note that national data protection regulations in your or our country of residence
            may also apply. If specific legal bases are relevant in individual cases, we will inform you of these in the privacy policy.</p>
        <ul>
            <li><strong>Consent (Art. 6 (1) sentence 1 lit. a) GDPR)</strong> - The data subject has given their consent to the processing of their personal data for one or more specific purposes.</li>
            <li><strong>Performance of a contract and pre-contractual inquiries (Art. 6 (1) sentence 1 lit. b) GDPR)</strong> - The processing is necessary for the performance of a contract to which the data subject is a party or to take steps at the request
                of the data subject prior to entering into a contract.</li>
            <li><strong>Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR)</strong> - The processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden
                by the interests or fundamental rights and freedoms of the data subject which require protection of personal data.</li>
        </ul>
        <p><strong>National data protection regulations in Germany: </strong>In addition to the data protection regulations of the GDPR, national regulations on data protection apply in Germany. This includes, in particular, the Federal Data Protection Act
            (BDSG). The BDSG contains specific provisions on the right to access, the right to erasure, the right to object, the processing of special categories of personal data, processing for other purposes, and transmission and automated decision-making
            in individual cases, including profiling. In addition, the data protection laws of the individual federal states may apply.</p>
        <p><strong>Note on the applicability of GDPR and Swiss DSG: </strong>These privacy notices serve both the information requirements under the Swiss Federal Act on Data Protection (Swiss DSG) and the General Data Protection Regulation (GDPR). Therefore,
            please note that the terms used in the GDPR are used for reasons of broader spatial application and comprehensibility. In particular, instead of the terms used in the Swiss DSG "processing" of "personal data," "overriding interest," and "sensitive
            personal data," the terms used in the GDPR "processing" of "personal data" and "legitimate interest" and "special categories of data" are used. However, the legal meaning of the terms will still be determined under the Swiss DSG within the
            scope of the Swiss DSG.</p>

        <h2 id="m27">Security Measures</h2>
        <p>We take appropriate technical and organizational measures to ensure a level of protection appropriate to the risk in accordance with legal requirements, considering the state of the art, implementation costs, and the nature, scope, context, and
            purposes of the processing, as well as the varying likelihood and severity of the risk to the rights and freedoms of natural persons.</p>
        <p>The measures include, in particular, ensuring the confidentiality, integrity, and availability of data by controlling physical and electronic access to the data as well as access to the data, input, transmission, ensuring availability, and separation.
            Furthermore, we have established procedures that ensure the exercise of data subject rights, the deletion of data, and responses to data threats. We also consider the protection of personal data during the development or selection of hardware,
            software, and procedures according to the principle of data protection by design and by default.</p>

        <h2 id="m25">Transfer of Personal Data</h2>
        <p>In the context of our processing of personal data, it may happen that the data is transferred to other bodies, companies, legally independent organizational units, or persons, or they are disclosed to them. The recipients of this data may include,
            for example, IT service providers or providers of services and content that are embedded in a website. In such cases, we comply with the legal requirements and, in particular, conclude corresponding contracts or agreements that serve the protection
            of your data with the recipients of your data.</p>

        <h2 id="m24">International Data Transfers</h2>
        <p>Data processing in third countries: If we process data in a third country (i.e., outside the European Union (EU), the European Economic Area (EEA)), or if the processing takes place in the context of the use of third-party services or the disclosure
            or transfer of data to other persons, bodies, or companies, this is done only in accordance with legal requirements. If the data protection level in the third country is recognized by an adequacy decision (Art. 45 GDPR), this serves as the
            basis for the data transfer. Otherwise, data transfers will only take place if the data protection level is otherwise ensured, particularly through standard contractual clauses (Art. 46 (2) lit. c) GDPR), explicit consent, or in the case of
            contractual or legally required transfers (Art. 49 (1) GDPR). Furthermore, we inform you of the bases of the third country transfer for each provider from the third country, with the adequacy decisions being the primary basis. Information
            on third country transfers and existing adequacy decisions can be found on the EU Commission's information page: <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de" target="_blank">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.</a></p>
        <p>EU-US Trans-Atlantic Data Privacy Framework: Within the framework of the so-called "Data Privacy Framework" (DPF), the EU Commission has also recognized the data protection level for certain companies from the USA as secure in the adequacy decision
            of July 10, 2023. The list of certified companies and further information on the DPF can be found on the website of the US Department of Commerce at <a href="https://www.dataprivacyframework.gov/" target="_blank">https://www.dataprivacyframework.gov/</a>            (in English). We inform you in our privacy notices which of the service providers we use are certified under the Data Privacy Framework.</p>

        <h2 id="m10">Rights of Data Subjects</h2>
        <p>Rights of data subjects under the GDPR: As a data subject, you have various rights under the GDPR, which arise in particular from Art. 15 to 21 GDPR:</p>
        <ul>
            <li><strong>Right to object: You have the right to object at any time, for reasons arising from your particular situation, to the processing of personal data concerning you based on Art. 6 (1) lit. e or f GDPR; this also applies to profiling based on these provisions. If the personal data concerning you is processed for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for such marketing; this also applies to profiling to the extent that it is related to such direct marketing.</strong></li>
            <li><strong>Right to withdraw consent:</strong> You have the right to withdraw consents granted at any time.</li>
            <li><strong>Right of access:</strong> You have the right to obtain confirmation as to whether or not data concerning you is being processed and to receive information about this data, as well as further information and a copy of the data in accordance
                with legal requirements.</li>
            <li><strong>Right to rectification:</strong> You have the right to request the completion of data concerning you or the rectification of inaccurate data concerning you in accordance with legal requirements.</li>
            <li><strong>Right to erasure and restriction of processing:</strong> You have the right to request that data concerning you be deleted immediately or, alternatively, to request a restriction of the processing of the data in accordance with legal
                requirements.
            </li>
            <li><strong>Right to data portability:</strong> You have the right to receive data concerning you that you have provided to us in a structured, commonly used, and machine-readable format, in accordance with legal requirements, or to request that
                it be transmitted to another controller.</li>
            <li><strong>Right to lodge a complaint with a supervisory authority:</strong> You have the right to lodge a complaint with a supervisory authority, in particular in the Member State of your habitual residence, place of work, or place of the alleged
                infringement, without prejudice to any other administrative or judicial remedy, if you believe that the processing of personal data concerning you violates the GDPR.</li>
        </ul>

        <h2 id="m134">Use of Cookies</h2>
        <p>Cookies are small text files or other memory markers that store information on end devices and read information from end devices. For example, to save the login status in a user account, the contents of a shopping cart in an e-shop, the accessed
            content, or used functions of an online offer. Cookies can also be used for different purposes, such as the functionality, security, and comfort of online offers and the creation of analyses of visitor flows.</p>
        <p><strong>Notes on consent: </strong>We use cookies in accordance with legal requirements. Therefore, we obtain prior consent from users unless it is not required by law. Consent is particularly not necessary if the storage and reading of the information,
            including cookies, is strictly necessary to provide users with a telemedia service (i.e., our online offer) they expressly request. Strictly necessary cookies generally include cookies with functions that are essential for displaying and running
            the online offer, load balancing, security, storing user preferences and options, or similar purposes related to providing the main and auxiliary functions of the online offer requested by users. The revocable consent is clearly communicated
            to users and includes information about the specific use of cookies.</p>
        <p><strong>Notes on the legal bases for data protection: </strong>The legal basis on which we process users' personal data using cookies depends on whether we ask users for consent. If users consent, the legal basis for processing their data is the
            declared consent. Otherwise, the data processed using cookies is based on our legitimate interests (e.g., in the economic operation of our online offer and improvement of its usability) or if it is necessary to fulfill our contractual obligations,
            if the use of cookies is necessary to fulfill our contractual obligations. The purposes for which cookies are processed by us are explained in this privacy policy or as part of our consent and processing procedures.</p>
        <p><strong>Storage duration: </strong>With regard to the storage duration, the following types of cookies are distinguished:</p>
        <ul>
            <li><strong>Temporary cookies (also: session cookies):</strong> Temporary cookies are deleted at the latest after a user has left an online offer and closed their end device (e.g., browser or mobile application).</li>
            <li><strong>Permanent cookies:</strong> Permanent cookies remain stored even after the end device is closed. For example, the login status can be saved, or preferred content can be displayed directly when the user visits a website again. Likewise,
                the data collected using cookies can be used to measure the reach. Unless we provide users with explicit information on the type and storage duration of cookies (e.g., when obtaining consent), users should assume that cookies are permanent
                and that the storage duration can be up to two years.</li>
        </ul>
        <p><strong>General information on revocation and objection (so-called "opt-out"): </strong>Users can revoke the consents they have given at any time and object to the processing in accordance with the legal requirements. Users can, among other things,
            restrict the use of cookies in the settings of their browser (although this may limit the functionality of our online offer). An objection to the use of cookies for online marketing purposes can also be declared via the websites <a href="https://optout.aboutads.info/"
                target="_new">https://optout.aboutads.info</a> and <a href="https://www.youronlinechoices.com/" target="_new">https://www.youronlinechoices.com/</a>.</p>
        <ul class="m-elements">
            <li class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR). Consent (Art. 6 (1) sentence 1 lit. a) GDPR).</li>
        </ul>
        <p><strong>Further information on processing, procedures, and services:</strong></p>
        <ul class="m-elements">
            <li><strong>Processing of cookie data based on consent: </strong>We use a consent management solution in which the consent of users to the use of cookies or to the procedures and providers mentioned in the consent management solution is obtained.
                This procedure serves the collection, documentation, management, and revocation of consents, particularly concerning the use of cookies and similar technologies used to store, read, and process information on the users' end devices. In
                this procedure, users' consents to the use of cookies and the related processing of information, including the specific processing and providers mentioned in the consent management procedure, are obtained. Users also have the option to
                manage and revoke their consents. The consent declarations are stored to avoid repeated queries and to provide proof of consent in accordance with legal requirements. The storage takes place server-side and/or in a cookie (so-called opt-in
                cookie) or by using similar technologies to be able to assign the consent to a specific user or their device. Unless specific information about the providers of consent management services is available, the following general information
                applies: The storage duration of the consent is up to two years. A pseudonymous user identifier is created, which is stored together with the time of consent, information on the scope of consent (e.g., concerning categories of cookies
                and/or service providers), as well as information on the browser, system, and end device used; <span class=""><strong>Legal bases:</strong> Consent (Art. 6 (1) sentence 1 lit. a) GDPR).</span></li>
        </ul>
        <h2 id="m225">Provision of the Online Offer and Web Hosting</h2>
        <p>We process users' data to provide them with our online services. For this purpose, we process the IP address of the user, which is necessary to deliver the content and functions of our online services to the user's browser or end device.</p>
        <ul class="m-elements">
            <li><strong>Processed data types:</strong> Usage data (e.g., visited websites, interest in content, access times). Meta, communication, and procedural data (e.g., IP addresses, timestamps, identification numbers, consent status).</li>
            <li><strong>Data subjects:</strong> Users (e.g., website visitors, users of online services).</li>
            <li><strong>Purposes of processing:</strong> Provision of our online offer and user-friendliness; information technology infrastructure (operation and provision of information systems and technical devices (computers, servers, etc.)). Security
                measures.
            </li>
            <li class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR).</li>
        </ul>
        <p><strong>Further information on processing, procedures, and services:</strong></p>
        <ul class="m-elements">
            <li><strong>Collection of access data and log files: </strong>Access to our online offer is logged in the form of so-called "server log files." The server log files may include the address and name of the accessed web pages and files, date and
                time of access, transferred data volumes, message about successful retrieval, browser type and version, the user's operating system, referrer URL (the previously visited page), and usually IP addresses and the requesting provider. The
                server log files can be used, on the one hand, for security purposes, e.g., to avoid overloading the servers (especially in the case of abusive attacks, so-called DDoS attacks) and, on the other hand, to ensure the stability and performance
                of the servers;
                <span class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR). </span><strong>Deletion of data:</strong> Log file information is stored for a maximum of 30 days and then deleted or anonymized. Data
                that requires further retention for evidentiary purposes is excluded from deletion until the respective incident is finally clarified.</li>
        </ul>
        <h2 id="m182">Contact and Request Management</h2>
        <p>When contacting us (e.g., by mail, contact form, email, phone, or via social media) and within the framework of existing user and business relationships, the information of the requesting persons is processed to the extent necessary to respond
            to the contact requests and any requested measures.</p>
        <ul class="m-elements">
            <li><strong>Processed data types:</strong> Contact data (e.g., email, phone numbers); content data (e.g., entries in online forms); usage data (e.g., visited websites, interest in content, access times). Meta, communication, and procedural data
                (e.g., IP addresses, timestamps, identification numbers, consent status).</li>
            <li><strong>Data subjects:</strong> Communication partners.</li>
            <li><strong>Purposes of processing:</strong> Contact requests and communication; administration and response to inquiries; feedback (e.g., collecting feedback via online form). Provision of our online offer and user-friendliness.</li>
            <li class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR). Performance of a contract and pre-contractual inquiries (Art. 6 (1) sentence 1 lit. b) GDPR).</li>
        </ul>
        <p><strong>Further information on processing, procedures, and services:</strong></p>
        <ul class="m-elements">
            <li><strong>Contact form: </strong>If users contact us via our contact form, email, or other communication channels, we process the data provided to us in this context to handle the reported concern; <span class=""><strong>Legal bases:</strong> Performance of a contract and pre-contractual inquiries (Art. 6 (1) sentence 1 lit. b) GDPR), legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR).</span></li>
        </ul>
        <h2 id="m136">Social Media Presence</h2>
        <p>We maintain online presences within social networks and process users' data in this context to communicate with the users active there or to offer information about us.</p>
        <p>We point out that data of users may be processed outside the territory of the European Union. This can result in risks for the users, for example, because it could make it more difficult to enforce users' rights.</p>
        <p>Furthermore, the data of users within social networks are usually processed for market research and advertising purposes. For example, usage profiles can be created based on the usage behavior and the resulting interests of the users. The usage
            profiles can, in turn, be used to place advertisements inside and outside the networks that presumably correspond to the users' interests. For these purposes, cookies are usually stored on the users' computers, in which the usage behavior
            and interests of the users are stored. Furthermore, data may also be stored in the usage profiles regardless of the devices used by the users (especially if the users are members of the respective platforms and logged in to them).</p>
        <p>For a detailed description of the respective forms of processing and the opt-out options, we refer to the privacy policies and information provided by the operators of the respective networks.</p>
        <p>Also, in the case of information requests and the assertion of data subject rights, we point out that these are most effectively enforced with the providers. Only the providers have access to the data of the users and can take direct measures
            and provide information. If you still need help, you can contact us.</p>
        <ul class="m-elements">
            <li><strong>Processed data types:</strong> Contact data (e.g., email, phone numbers); content data (e.g., entries in online forms); usage data (e.g., visited websites, interest in content, access times). Meta, communication, and procedural data
                (e.g., IP addresses, timestamps, identification numbers, consent status).</li>
            <li><strong>Data subjects:</strong> Users (e.g., website visitors, users of online services).</li>
            <li><strong>Purposes of processing:</strong> Contact requests and communication; feedback (e.g., collecting feedback via online form). Marketing.</li>
            <li class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR).</li>
        </ul>
        <p><strong>Further information on processing, procedures, and services:</strong></p>
        <ul class="m-elements">
            <li><strong>LinkedIn: </strong>Social network; <strong>Service provider:</strong> LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland; <span class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR); </span><strong>Website:</strong>                <a href="https://www.linkedin.com" target="_blank">https://www.linkedin.com</a>; <strong>Privacy policy:</strong> <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>;
                <strong>Basis for third-country transfers:</strong> Data Privacy Framework (DPF); <strong>Opt-out option:</strong> <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
                <strong>Further information:</strong> We are jointly responsible with LinkedIn Ireland Unlimited Company for the collection (but not the further processing) of data of visitors, which is done for the purposes of creating "Page Insights"
                (statistics) of our LinkedIn profiles. These data include information about the types of content users view or interact with or the actions they take, as well as information about the devices used by users (e.g., IP addresses, operating
                system, browser type, language settings, cookie data) and information from users' profiles, such as job function, country, industry, seniority, company size, and employment status. Privacy information about the processing of users' data
                by LinkedIn can be found in LinkedIn's privacy policy:
                <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>. We have entered into a special agreement with LinkedIn Ireland ("Page Insights Joint Controller Addendum (the 'Addendum')",
                <a href="https://legal.linkedin.com/pages-joint-controller-addendum" target="_blank">https://legal.linkedin.com/pages-joint-controller-addendum</a>), which, in particular, regulates which security measures LinkedIn must observe and in
                which LinkedIn has agreed to fulfill the data subject rights (i.e., users can, for example, address inquiries for information or deletion requests directly to LinkedIn). The rights of users (in particular to information, deletion, objection,
                and complaint to the competent supervisory authority) are not restricted by the agreements with LinkedIn. The joint responsibility is limited to the collection of data and its transmission to the Ireland Unlimited Company, a company based
                in the EU. The further processing of the data is the sole responsibility of the Ireland Unlimited Company, which includes, in particular, the transfer of the data to the parent company LinkedIn Corporation in the USA.</li>
            <li><strong>Xing: </strong>Social network; <strong>Service provider:</strong> New Work SE, Am Strandkai 1, 20457 Hamburg, Germany; <span class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR); </span><strong>Website:</strong>                <a href="https://www.xing.com/" target="_blank">https://www.xing.com/</a>. <strong>Privacy policy:</strong> <a href="https://privacy.xing.com/en/privacy-policy" target="_blank">https://privacy.xing.com/en/privacy-policy</a>.</li>
        </ul>
        <h2 id="m328">Plugins and Embedded Functions and Content</h2>
        <p>We integrate functional and content elements into our online offer that are obtained from the servers of their respective providers (hereinafter referred to as "third-party providers"). These may be, for example, graphics, videos, or city maps
            (hereinafter uniformly referred to as "content").</p>
        <p>The integration always requires that the third-party providers of this content process the IP address of the users, as they could not send the content to their browsers without the IP address. The IP address is therefore required for displaying
            this content or functions. We endeavor to use only such content whose respective providers use the IP address solely to deliver the content. Third-party providers may also use so-called pixel tags (invisible graphics, also known as "web beacons")
            for statistical or marketing purposes. The "pixel tags" can be used to evaluate the visitor traffic on the pages of this website. The pseudonymous information may also be stored in cookies on the users' devices and may include, among other
            things, technical information about the browser and operating system, referring websites, visit times, and other information on the use of our online offer, as well as be linked to such information from other sources.</p>
        <ul class="m-elements">
            <li><strong>Processed data types:</strong> Usage data (e.g., visited websites, interest in content, access times). Meta, communication, and procedural data (e.g., IP addresses, timestamps, identification numbers, consent status).</li>
            <li><strong>Data subjects:</strong> Users (e.g., website visitors, users of online services).</li>
            <li><strong>Purposes of processing:</strong> Provision of our online offer and user-friendliness.</li>
            <li class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR).</li>
        </ul>
        <p><strong>Further information on processing, procedures, and services:</strong></p>
        <ul class="m-elements">
            <li><strong>Google Fonts (retrieval from Google server): </strong>Retrieval of fonts (and symbols) for the purpose of a technically secure, maintenance-free, and efficient use of fonts and symbols regarding timeliness and loading times, their
                uniform display, and consideration of possible licensing restrictions. The IP address of the user is communicated to the provider of the fonts to make the fonts available in the user's browser. In addition, technical data (language settings,
                screen resolution, operating system, hardware used) is transmitted, which is necessary for the provision of the fonts depending on the devices used and the technical environment. This data may be processed on a server of the font provider
                in the USA - When visiting our online offer, the users' browsers send their browser HTTP requests to the Google Fonts Web API (i.e., a software interface for retrieving the fonts). The Google Fonts Web API provides the users with the Cascading
                Style Sheets (CSS) of Google Fonts and then the fonts specified in the CSS. These HTTP requests include (1) the IP address used by the respective user to access the Internet, (2) the requested URL on the Google server, and (3) the HTTP
                headers, including the user agent, which describes the browser and operating system versions of the website visitors, as well as the referrer URL (i.e., the website on which the Google font is to be displayed). IP addresses are neither
                logged nor stored on Google servers, nor are they analyzed. The Google Fonts Web API logs details of the HTTP requests (requested URL, user agent, and referrer URL). Access to this data is restricted and strictly controlled. The requested
                URL identifies the font families the user wants to load fonts for. This data is logged so that Google can determine how often a particular font family is requested. For the Google Fonts Web API, the user agent must customize the font generated
                for the respective browser type. The user agent is primarily logged for debugging and used to generate aggregated usage statistics to measure the popularity of font families. These aggregated usage statistics are published on the "Analytics"
                page of Google Fonts. Finally, the referrer URL is logged so that the data can be used for production maintenance, and an aggregated report on the top integrations can be generated based on the number of font requests. According to Google,
                none of the information collected by Google Fonts is used to create user profiles or to display targeted ads; <strong>Service provider:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland; <span class=""><strong>Legal bases:</strong> Legitimate interests (Art. 6 (1) sentence 1 lit. f) GDPR); </span><strong>Website:</strong>                <a href="https://fonts.google.com/" target="_blank">https://fonts.google.com/</a>; <strong>Privacy policy:</strong> <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>; <strong>Basis for third-country transfers:</strong>                Data Privacy Framework (DPF). <strong>Further information:</strong> <a href="https://developers.google.com/fonts/faq/privacy?hl=en" target="_blank">https://developers.google.com/fonts/faq/privacy?hl=en</a>.</li>
        </ul>
        <p class="seal"><a href="https://datenschutz-generator.de/" title="Legal text by Dr. Schwenke - for more information please click." target="_blank" rel="noopener noreferrer nofollow">Created with the free privacy generator.de by Dr. Thomas Schwenke</a></p>
    </div>

    }
</div>