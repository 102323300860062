<section id="my-skills" class="z-index10" id="my-skills">
    <div class="d-flex z-index10 left-container">

        <div class="container-img">
            <img src="./../../../assets/skills-icons/angular.svg" alt="">
            <div>Angular</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/api.svg" alt="">
            <div>API</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/firebase.svg" alt="">
            <div>Firebase</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/github.svg" alt="">
            <div>Github</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/material.svg" alt="">
            <div class="dubble-text">
                <span>Material</span>
                <span>Design</span>
            </div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/html_5.svg" alt="">
            <div>HTML</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/dbs_sqlite.svg" alt="">

            <div class="dubble-text">
                <span>dbs</span>
                <span>sqlite</span>
            </div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/django.svg" alt="">
            <div>Django</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/docker.svg" alt="">
            <div>Docker</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/gimp.svg" alt="">
            <div>Gimp</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/git.svg" alt="">
            <div>Git</div>
        </div>
        <div class="container-img">
            <img src="./../../../assets/skills-icons/google_cloud.svg" alt="">
            <div class="dubble-text">
                <span>Google</span>
                <span>Cloud</span>
            </div>
        </div>

        <div class="container-img">
            <img src="./../../../assets/skills-icons/heroku.svg" alt="">
            <div>Heroku</div>
        </div>

    </div>
    <div class="right-container text-padding-right" data-aos="fade-down-left">
        <div class="head-container">
            <app-chevron-icon></app-chevron-icon>
            <div class="header ">
                @if (translateService.de) {
                <h2 class="section-heading ">Meine Skills</h2>
                }@if (translateService.en) {
                <h2 class="section-heading ">My Skills</h2>
                }
                <div class="horizontal-line" style="position:absolute; right: 0; "></div>
            </div>
        </div>
    </div>
</section>