<app-displacement-sphere></app-displacement-sphere>
<section id="hero-section">
    <div class="text-container" style="width: 100%;">
        <div class="animated-header">
            <span class="char" style="animation-delay: 0s;">S</span>
            <span class="char" style="animation-delay: 0.1s;">w</span>
            <span class="char" style="animation-delay: 0.2s;">e</span>
            <span class="char" style="animation-delay: 0.3s;">n</span>
            <span class="char" style="animation-delay: 0.4s;">&nbsp;</span>
            <span class="char" style="animation-delay: 0.5s;">B</span>
            <span class="char" style="animation-delay: 0.6s;">r</span>
            <span class="char" style="animation-delay: 0.7s;">e</span>
            <span class="char" style="animation-delay: 0.8s;">i</span>
            <span class="char" style="animation-delay: 0.9s;">t</span>
            <span class="char" style="animation-delay: 1.0s;">u</span>
            <span class="char" style="animation-delay: 1.1s;">n</span>
            <span class="char" style="animation-delay: 1.2s;">g</span>

        </div>
        <div class="header-container">
            <div class="header-text-container">
                <div class="background-animation"></div>
                <h1 class="header-text" [innerHTML]="currentWord"></h1>
            </div>
            <div class="line"></div>
        </div>
        <h1 class="sub-header-text">Developer</h1>
    </div>
    <div (click)="layoutService.scrollToSection('my-projects')" class="scroll-indicator cursor-pointer"></div>
</section>