<section id="contact" class="contact">
    <div class="header-container">

        <app-chevron-icon></app-chevron-icon>

        <div class="header z-index10">
            <div class="horizontal-line"></div>
            @if (translateService.de) {
            <h2 class="section-heading">Sag Hallo</h2>
            }@if (translateService.en) {
            <h2 class="section-heading">Sey hello</h2>
            }
        </div>
    </div>
    <div class="right-side  z-index10" data-aos="fade-left">
        <form #contactForm="ngForm" class="form-container" (ngSubmit)="onSubmit(contactForm)">
            <div class="width100p p8 textarea-container">
                <input type="text" placeholder="" name="name" [(ngModel)]="contactData.name" required minlength="3" #nameField="ngModel" (focus)="onFocus('name')" (blur)="onBlur('name', nameField)" />
                <label for="E-Mail" class="textarea-placeholder">Name</label>
                <div class="animated-line" #lineName></div>
                <div class="h28px">

                    @if (translateService.de) {
                    <div class="error" *ngIf="(nameField.invalid && nameField.touched && contactData.name.length > 0) || (contactForm.submitted && nameField.invalid && contactData.name.length > 0)">
                        Gebe bitte einen Namen mit midestens 3 Zeichen ein.
                    </div> } @if (translateService.en) {
                    <div class="error" *ngIf="(nameField.invalid && nameField.touched && contactData.name.length > 0) || (contactForm.submitted && nameField.invalid && contactData.name.length > 0)">
                        Please enter a name with at least 3 characters.
                    </div> }
                </div>
            </div>

            <div class="width100p p8 textarea-container">
                <input type="email" placeholder="" name="email" [(ngModel)]="contactData.email" required email #emailField="ngModel" (focus)="onFocus('email')" (blur)="onBlur('email', emailField)" />
                <label for="E-Mail" class="textarea-placeholder">E-Mail</label>
                <div class="animated-line" #lineEmail></div>
                <div class="h28px">
                    <div *ngIf="translateService.de">
                        <div class="error" *ngIf="(emailField.invalid && emailField.touched && contactData.email.length > 0) || (contactForm.submitted && emailField.invalid && contactData.email.length > 0)">
                            Gültige E-Mail ist erforderlich.
                        </div>
                    </div>
                    <div *ngIf="translateService.en">
                        <div class="error" *ngIf="(emailField.invalid && (emailField.touched || contactForm.submitted))">
                            A valid email is required.
                        </div>
                    </div>
                </div>

            </div>

            <div class="width100p p8 textarea-container">
                <textarea placeholder="" name="message" [(ngModel)]="contactData.message" required minlength="20" #messageField="ngModel" (focus)="onFocus('message')" (blur)="onBlur('message', messageField)" (input)="autoGrow($event)"></textarea>
                <label for="message" class="textarea-placeholder">Message</label>
                <div class="animated-line" #lineTextarea></div>
                <div class="h28px">
                    @if (translateService.de) {
                    <div class="error" *ngIf="(messageField.invalid && messageField.touched && contactData.message.length > 0) || (contactForm.submitted && messageField.invalid && contactData.message.length > 0)">
                        Gebe bitte Midestens 20 Zeichen ein.
                    </div>}@if (translateService.en) {
                    <div class="error" *ngIf="(messageField.invalid && messageField.touched && contactData.message.length > 0) || (contactForm.submitted && messageField.invalid && contactData.message.length > 0)">
                        Please enter at least 20 characters.
                    </div>}
                </div>
            </div>

            <div class="data-agreement-section">
                <label class="custom-checkbox">
                        <input name="checkbox" type="checkbox" [(ngModel)]="isAgreed">
                        <div class="checkmark"></div>
                    </label> @if (translateService.de) {
                <div class="d-flex checkbox-text">
                    <span> Ich habe die </span>
                    <div class="police-container cursor-pointer" (click)="navigateToPolicy()">
                        &nbsp;Datenschutzrichtlinie&nbsp; </div>
                    <span> gelesen und stimme der Verarbeitung meiner Daten wie beschrieben zu.</span>
                </div>
                } @if (translateService.en) {
                <div class="d-flex checkbox-text">
                    <span> I've read the </span>
                    <br>
                    <div class="police-container" (click)="navigateToPolicy()">
                        &nbsp;Policy&nbsp; </div><br>
                    <span> and agree to the processing of my data as outlined.</span>
                </div>
                }
            </div>
            @if (translateService.de) {
            <app-button-standard type="submit" [buttonText]="'Nachricht senden'" [isDisabled]="!contactForm.valid || !isAgreed"></app-button-standard>
            }@if (translateService.en) {
            <app-button-standard type="submit" [buttonText]="'Send message'" [isDisabled]="!contactForm.valid || !isAgreed"></app-button-standard>
            }
        </form>
    </div>



    <div *ngIf="isLoading" class="loading-container">
        @if (translateService.de) {
        <div class="loading-bar">
            Lädt...
        </div>
        } @if (translateService.en) {
        <div class="loading-bar">
            Loading...
        </div>}
    </div>

    <div (click)="torgleIsSending()" *ngIf="isSending" class="loading-container">
        <div class="sending-button">
            @if(isError){ @if (translateService.de) {
            <h1>Die Nachricht konnte nicht gesendet werden.</h1> } @if (translateService.en) {
            <h1>The message could not be sent.</h1> } } @if(!isError){ @if (translateService.de) {
            <h1>Die Nachricht wurde gesendet.</h1>
            } @if (translateService.en) {
            <h1>The message has been sent.</h1>> } }
        </div>
    </div>


</section>