<section class="z-index10" id="my-projects">
    <div class="header-all-text">
        <div class="header">
            <h2 class="section-heading">Portfolio</h2>
            <div class="horizontal-line long-line"></div>
        </div>
        <div>@if (translateService.de) {
            <span class="info-text">
                Hey, hier ist ein kleiner Einblick in meine Arbeit – fühl dich frei, alles in Ruhe durchzuschauen.
            </span>}@if (translateService.en) {
            <span class="info-text">
                Hey, here's a glimpse of my work – feel free to check it out at your own pace.
            </span>}
        </div>
    </div>
</section>

<section class="skill-overlay">
    <!--  -->
    <div class="skill-container-img-left z-index10">
        <div class="skill-container-img-left z-index10">
            <div class="container-left-text">
                <div class="container-left-text">
                    <div class="header-container">
                        <div class="chevron-container">
                            <app-chevron-icon></app-chevron-icon>
                            <span>01</span>
                        </div>
                        <h3>DABubble</h3>
                    </div>
                    <h4>Angular | Firebase | Material Design</h4>
                    @if (translateService.de) {
                    <span class="text-left">
                            Ich habe einen Slack-Klon mit Angular, Firebase und Material Design entwickelt. Diese App ermöglicht Echtzeit-Chats, private Nachrichten und das Erstellen von Kanälen – ideal für Teams, die eine schnelle und effiziente Kommunikation benötigen.
                        </span> } @if (translateService.en) {
                    <span class="text-left">
                            I've developed a Slack clone using Angular, Firebase, and Material
                            Design. This app enables real-time chats, private messaging, and
                            channel creation—ideal for teams in need of fast and streamlined
                            communication.  
                        </span> }
                </div>
                <div class="button-container d-flex gap8">
                    <a href="https://github.com/SwenBreitung/daBubble" target="_blank" rel="noopener noreferrer">
                        <app-button-right [buttonText]="'GitHub'"></app-button-right>
                    </a>
                    <a href="https://da-Bubble.swen-breitung.de/" target="_blank" rel="noopener noreferrer">
                        <app-button-standard [buttonText]="'Live'"></app-button-standard>
                    </a>
                </div>
            </div>
            <app-three-js-animation [textureUrl]="'./../../../assets/projects-img/da_bubble_screen.png'"></app-three-js-animation>
        </div>
    </div>
    <!--  -->
    <div class="skill-container-img-right z-index10">
        <div class="skill-container-img-right">
            <div class="skill-container-img-right z-index10">
                <app-three-js-animation [textureUrl]="'./../../../assets/projects-img/ell_polloco_screen.png'"></app-three-js-animation>
                <div class="container-right-text">
                    <div class="container-right-text">
                        <div class="header-container">
                            <div class="chevron-container">
                                <app-chevron-icon></app-chevron-icon>
                                <span>02</span>
                            </div>
                            <h3>EllPolloloco</h3>
                        </div>
                        <h4>JavaScript | HTML | CSS</h4>
                        @if (translateService.de) {
                        <span class="text-left">
                            Ell Polloco ist ein kompaktes Jump-and-Run-Spiel im Cartoon-Stil. Das Ziel ist es, Gegner mit gezielten Sprüngen zu besiegen und den Endgegner durch clever eingesetzte Salsa-Flaschen auszutricksen. Besondere Vorsicht ist bei den kleinen Küken geboten, die vom Boss beschworen werden und den Spieler angreifen.
                        </span> } @if (translateService.en) {
                        <span class="text-left">
                            Ell Polloco' is a compact jump-and-run game in a cartoon style.
                            Here, the objective is to defeat enemies with targeted jumps and
                            outsmart the final boss using cleverly deployed salsa bottles.
                            Special attention is required for the small chicks summoned by the
                            boss, which attack the player.
                        </span> }
                    </div>
                    <div class="button-container d-flex gap8">
                        <a href="https://github.com/SwenBreitung/EllPolloloco" target="_blank" rel="noopener noreferrer">
                            <app-button-right [buttonText]="'GitHub'"></app-button-right>
                        </a>
                        <a href="http://flix-tube.swen-breitung.de/ell-polloco.swen-breitung.de/" target="_blank" rel="noopener noreferrer">
                            <app-button-standard [buttonText]="'Live'"></app-button-standard>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--  -->
    <div class="skill-container-img-left z-index10">
        <div class="skill-container-img-left z-index10">
            <div class="container-left-text">
                <div class="header-container">
                    <div class="chevron-container">
                        <app-chevron-icon></app-chevron-icon>
                        <span>03</span>
                    </div>
                    <h3>Join</h3>
                </div>
                <h4>JavaScript | HTML | CSS</h4>
                @if (translateService.de) {
                <span class="text-left">
                            Mein Kanban-Board kombiniert Kontaktmanagement und Aufgabenplanung in einem nahtlosen, benutzerfreundlichen Tool. Es ermöglicht die effiziente Speicherung von Kontakten, die einfache Erstellung von Aufgaben und deren strukturierte Organisation. Mit unserer intuitiven Drag-and-Drop-Oberfläche können Sie Ihre Projekte immer im Blick behalten und Ihre Produktivität steigern.
                        </span>}@if (translateService.en) { <span class="text-left">
                            My Kanban board combines contact management and task planning in a seamless, user-friendly tool. It allows for efficient storage of contacts, easy creation of tasks, and
                            their structured organization. With our intuitive drag-and-drop interface, you can always keep track of your projects and boost your productivity. 
                        </span>}
                <div class="button-container d-flex gap8" style="">
                    <a href="https://github.com/SwenBreitung/join" target="_blank" rel="noopener noreferrer">
                        <app-button-right [buttonText]="'GitHub'"></app-button-right>
                    </a>
                    <a href="https://join.swen-breitung.de/" target="_blank" rel="noopener noreferrer">
                        <app-button-standard [buttonText]="'Live'"></app-button-standard>
                    </a>
                </div>
            </div>
            <app-three-js-animation [textureUrl]="'./../../../assets/projects-img/join_screen.png'"></app-three-js-animation>
        </div>
    </div>

    <!--  -->
    <div class="skill-container-img-right z-index10">
        <div class="skill-container-img-right z-index10">
            <app-three-js-animation></app-three-js-animation>
            <div class="container-left-text">
                <div class="header-container">
                    <div class="chevron-container">
                        <app-chevron-icon></app-chevron-icon>
                        <span>04</span>
                    </div>
                    <h3>Flix Tube</h3>
                </div>
                <h4>Angular | HTML | CSS | DRF</h4>
                @if (translateService.de) {
                <span class="text-left">
                    Flix Tube ist eine Videoplattform die auf Youtube aufgebaut ist. Diese
                    wird im kern mit Angular sowie DRF geschrieben wo man selbst Videos in
                    unterschiedlichen Auflösungen hochladen kann. Außerdem hat diese noch
                    einen zusätzlichen Simple Chat.
                        </span>}@if (translateService.en) { <span class="text-left">
                            Flix Tube is a video platform built on the foundation of YouTube. It is primarily developed using Angular and Django REST Framework (DRF). On this platform, users can upload their own videos in various resolutions. Additionally, Flix Tube features a simple chat.
                        </span>}
                <div class="button-container d-flex gap8">
                    <a href="https://github.com/SwenBreitung/flix-tube-frontend" target="_blank" rel="noopener noreferrer">
                        <app-button-right [buttonText]="'GitHub'"></app-button-right>
                    </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <app-button-standard [buttonText]="'Live'" [isDisabled]="true"></app-button-standard>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>