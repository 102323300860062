<section>
    @if (translateService.de) {
    <div class='impressum-container'>
        <h1>Impressum</h1>
        <p>Angaben gemäß § 5 TMG</p>
        <p>
            <span> Swen Breitung </span>
            <br> Heutalsweg 28
            <br> 99867 Gotha <br>
        </p>
        <p>
            <strong>Vertreten durch: </strong>
            <br> Swen Breitung<br>
        </p>
        <p><strong>Kontakt:</strong>
            <br> Telefon: +49 152- 599 433 68
            <br> E-Mail: <a href='mailto:veroxa@web.de'>E-Mail</a>

        </p>
        <br> Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a>
        <span>der </span>
        <a href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/" rel="nofollow"> Kanzlei Hasselbach, Frankfurt</a>
    </div>
    } @if (translateService.en) {
    <div class='impressum-container'>
        <h1>Imprint</h1>
        <p>Information according to § 5 TMG</p>
        <p>
            <span> Swen Breitung </span>
            <br> Heutalsweg 28
            <br> 99867 Gotha <br>
        </p>
        <p>
            <strong>Represented by: </strong>
            <br> Swen Breitung<br>
        </p>
        <p><strong>Contact:</strong>
            <br> Phone: +49 152- 599 433 68
            <br> Email: <a href='mailto:veroxa@web.de'>Email</a>
        </p>
        <br> Imprint from <a href="https://www.impressum-generator.de">Impressum Generator</a>
        <span>of </span>
        <a href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/" rel="nofollow"> Kanzlei Hasselbach, Frankfurt</a>
    </div>
    }
</section>