<section>

    <app-hero-section></app-hero-section>

    <app-about-me></app-about-me>
    <div class="p50px"></div>
    <app-my-projects></app-my-projects>
    <div class="p50px"></div>
    <app-my-skills></app-my-skills>
    <div class="p50px"></div>
    <app-contact></app-contact>
    <div class="p50px"></div>
    <app-footer></app-footer>
</section>