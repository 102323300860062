<section>
    <div class="line"></div>
    <div class="footer-container">

        <span>
                © 2024 Swen Breitung
            </span>
        <div>
            <span>Desingt by</span>
            <a href="https://github.com/HamishMW"> Hamish Williams </a>
        </div>


        <a (click)="openImpressumInNewTab()">Impressum</a>
    </div>

</section>