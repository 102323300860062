<section id="about-me">
    <div class="text-container">
        <h3 class="animated-text">
            @if (translateService.de) {
            <!-- <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="H">H</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="e">e</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="y">y</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=" ">&nbsp;</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="D">D</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="u">u</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=",">,</span> } @if (translateService.en) {
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="H">H</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="i">i</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=" ">&nbsp;</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="t">t</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="h">h</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="e">e</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="r">r</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="e">e</span>
            <span [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=",">,</span> } -->

            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="H">H</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="a">a</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="o">o</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=" "> </h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="W">W</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="e">e</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="t">t</h2>} @if (translateService.en) {
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="h">h</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="e">e</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="o">o</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text=" "> </h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="W">W</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="o">o</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="r">r</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="l">l</h2>
            <h2 [ngStyle]="{ 'opacity': isVisible ? '1' : '0' }" data-final-text="d">d</h2> }
        </h3>

        <span>
            @if (translateService.de) {
                Ich heiße Swen und lebe derzeit in Gotha. Beruflich habe ich eine Weiterbildung im Frontend- und Backend-Bereich im Rahmen meines BFD absolviert und bereits mehrere Projekte in Angular, TypeScript und JavaScript umgesetzt. Ich lade dich herzlich ein, meine Webseite zu besuchen und in meinen Projekten zu stöbern. Derzeit arbeite ich an einem Frontend- und Backend-Projekt, das YouTube nachbildet. Mein nächstes Vorhaben wird ein GitHub-Klon sein.                
                In meiner Freizeit war ich früher semiprofessioneller Leistungsschwimmer, musste diesen Sport jedoch leider aufgrund einer Verletzung aufgeben. Heute gehe ich gerne spazieren, spiele Videospiele und beschäftige mich intensiv mit IT-Themen, insbesondere mit der Erstellung von Prompts.
            }
            @if (translateService.en) {
                My name is Swen, and I currently live in Gotha. Professionally, I completed training in frontend and backend development as part of my BFD and have already implemented several projects using Angular, TypeScript, and JavaScript. I warmly invite you to visit my website and explore my projects. At the moment, I am working on a frontend and backend project that replicates YouTube. My next project will be a GitHub clone.  
                In my free time, I used to be a semi-professional competitive swimmer, but unfortunately, I had to give up the sport due to an injury. Nowadays, I enjoy taking walks, playing video games, and focusing intensively on IT topics, particularly the creation of prompts.
            }
        </span>
        <app-hover-button></app-hover-button>

    </div>
    <div class="img-container">
        <div class="header-container">
            <app-chevron-icon></app-chevron-icon>
            <span>About Me</span>
        </div>
        <div id="animation-container">
            <div id="animation"></div>
            <img id="hero-image" class="animated-image" src="./../../../../assets/hero-img/hero_img_01.png" alt="profiele">
        </div>
    </div>
</section>